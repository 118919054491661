window.addEventListener('heyflow-screen-view', (event) => {
    // Log the screen view event
    console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // Log the submit event
    console.log('heyflow submit:', event.detail);
});

// Wait for the DOM to load before running the code
document.addEventListener('DOMContentLoaded', () => {
    const button = document.getElementById('id-00e0a308');

    if (button) {
        button.addEventListener('click', () => {
            // Your code here
            alert('Button with ID id-00e0a308 was clicked!');
        });
    } else {
        console.warn('Element with ID id-00e0a308 not found in the DOM.');
    }
});

